import { Button, Divider, FormControlLabel, IconButton, Switch, TextField, Tooltip, Typography } from '@mui/material';
import * as React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { calculateTaskSummary, IRoleSetting, ITask, primaryRoleSelector, versionSettingsSelector, versionTaskAddAction } from '../../../modules';
import { Close, Construction } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { TaskDescription } from './TaskDescription';
// import { TaskDescription } from './TaskDescription';

const Container = styled.div`

    .input-line-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 15px;
    }


    .hours {
        width: 60px;
        text-align: right;
        INPUT {
            text-align: right;

        }
    }

    .description-container {
        flex-basis: 150px;
    }

    .line-summary-container {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        align-items: center;
        /* width: 95px; */
        flex-basis: 130px;
    }

`;

export interface INewTask {
    sectionId: string | number;
    onCancel: () => void;
}

export const NewTask: React.FC<INewTask> = (props: INewTask) => {
    const dispatch = useDispatch();

    const {sectionId, onCancel} = props;

    const [name, setName] = React.useState('');
    const [hours, setHours] = React.useState(0);
    const [description, setDescription] = React.useState('');
    const [isOverride, setIsOverride] = React.useState(false);
    const [overrides, setOverrides] = React.useState<{[key: string]: number}>({});

    const settings = useSelector(versionSettingsSelector);
    const {mode, ranges, roles} = settings;

    const primaryRole = useSelector(primaryRoleSelector);

    const inputRef = React.useRef<HTMLInputElement>();

    const toggleOverride = () => {
        const _isOverride = !isOverride;

        // const task = {...props.task};
        // task.isOverride = _isOverride;

        const task: ITask = {
            hours,
            name,
            description: '',
            summary: {},
            overrides,
            isOverride: _isOverride,
            isVisible: true
        };
        
        if (_isOverride) {
            // move the task hours into the overrides
            // const hours = task.hours;
            const summary = calculateTaskSummary({...task, isOverride: false}, roles, ranges);

            task.overrides = Object.entries(summary)
                .filter(([name, ]) => name !== 'Total')
                .reduce((acc, [name, taskSummary]) => {
                    return {...acc, [name]: taskSummary.hours}
                }, {})

            task.overrides[primaryRole!.role] = hours;

            task.hours = 0;
        } else {

            // add up the total hours from the override and set it as the task hours
            // task.hours = Object.entries(task.overrides || {})
            //     .filter(([role, hours]) => role === primaryRole!.role)
            //     .map(([, hours]) => hours || 0)
            //     .reduce((agg, curr) => agg + curr, 0);
            task.hours = task.overrides![primaryRole!.role] || 0;

            task.overrides = {};
        }

        task.summary = calculateTaskSummary(task, roles, ranges);

        // props.onChange(task);

        setIsOverride(_isOverride);
        setOverrides(task.overrides);
    };

    const onSaveClick = () => {

        if (name !== '' && hours > -1) {
            const task: ITask = {
                name,
                hours,
                description: '',
                isOverride,
                isVisible: true,
                overrides,
                summary: {}
            };

            const summary = calculateTaskSummary(task, roles, ranges);

            task.summary = summary;

            dispatch(versionTaskAddAction({ task, sectionId }));

            setName('');
            setHours(0);
            setDescription('');
            setOverrides({});
            // setIsOverride(false);

            inputRef.current?.focus();
        }
    };

    const onOverrideHoursChange = (role: string, val: string) => {
        const parsedHours = parseFloat(val);
        setOverrides({...overrides, [role]: parsedHours});
    };

    const overrideEls = roles
        .filter((x: IRoleSetting) => x.enabled)
        .sort((a, b) => {
            if (a.isPrimary) return -1;
            else if (!a.isPrimary) return 1;
            else return 0;
        })
        .map((x: IRoleSetting, index: number) => {
            const value = (overrides[x.role]) ? overrides[x.role] : 0;
            return (
                <TextField
                    key={index}
                    variant="standard"
                    style={{marginLeft: '2px'}}
                    size="small"
                    inputProps={{'data-testid': `${x.role}-override-hours`, min: 0, max: 99999, maxLength: 5}}
                    className="hours"
                    // type="number"
                    label={`${x.role} hrs`}
                    value={value}
                    draggable={true}
                    onDragStart={(event) => event.preventDefault()}
                    onChange={(event) => onOverrideHoursChange(x.role, event.target.value)}
                    // onBlur={(e) => onOverrideBlur(x.role, e)}
                    // onFocus={onFocus}
                />
            )
        });

    return (
        <Container>
            <div>
                <Typography variant="h6">
                    <span>Add Task</span>
                </Typography>
                <Divider />
            </div>
            <div className="input-line-container">
                <div style={{flexBasis: '75%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'end'}}>
                    <div style={{flexGrow: 1}}>
                        <TextField
                            className="task"
                            variant="standard"
                            size="small"
                            // InputLabelProps={{ shrink: false }}
                            inputProps={{'data-testid': `name`}}
                            label="Task"
                            // placeholder="Task"
                            value={name}
                            inputRef={inputRef}
                            onChange={(e) => setName(e.target.value)}
                            draggable={false}
                            onDragStart={(event) => event.preventDefault()}
                            fullWidth={true}
                            // error={nameError !== ''}
                            // helperText={nameError}
                            multiline={true}
                            sx={{
                                "& .MuiInputBase-input": {
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                }
                            }}
                            style={{minWidth: '200px'}}
                        />
                    </div>
                    <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'flex-end'}}>
                        { 
                            !isOverride && 
                            <TextField
                                className="hours"
                                variant="standard"
                                size="small"
                                draggable={false}
                                style={{marginLeft: '5px'}}
                                inputProps={{'data-testid': `hours`, min: 0, max: 99999, maxLength: 5}}
                                // type="number"
                                label={`${primaryRole?.role} Hours`}
                                value={hours}
                                onChange={(e) => setHours(parseInt(e.target.value))}
                                // onBlur={onFieldBlur}
                                // onFocus={onFocus}
                                // error={hoursError !== ''}
                                // helperText={hoursError}
                            />
                        }
                        {
                            isOverride && overrideEls
                        }
                        {
                            (mode === 'COMPUTED') &&
                            <Tooltip title="Override Hours" placement="bottom">
                                <IconButton onClick={toggleOverride} data-testid="override" tabIndex={-1}>
                                    <Construction fontSize="inherit" color={isOverride ? 'primary' : 'disabled'} />
                                </IconButton>
                            </Tooltip>
                        }
                    </div>
                </div>
                <div className="description-container">
                    <TaskDescription value={description} onChange={(val) => setDescription(val)} isVisible={true} />
                </div>
                <div className="line-summary-container">
                    {/* <FormControlLabel control={<Switch defaultChecked />} label="Add another" /> */}
                    <Button variant="text" onClick={onCancel}>Cancel</Button>
                    <Button variant="contained" onClick={onSaveClick} style={{marginLeft: '10px'}}>Save</Button>
                </div>
            </div>
        </Container>
    );
};
